.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 100000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.63);
  transition: all 0.6s; }

.modal-content {
  background: #282828;
  width: 589px;
  border-radius: 6px;
  padding-top: 1px;
  padding-bottom: 10px;
  border-radius: 6px;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; }
  @media (max-width: 600px) {
    .modal-content {
      width: 98%; }
      .modal-content input {
        width: 63% !important; }
      .modal-content textarea {
        width: 88% !important; }
      .modal-content button {
        display: block;
        width: 90% !important;
        margin-bottom: 20px !important; } }

.active .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

.playlist-Modal.active,
.overlay.active {
  opacity: 1;
  visibility: visible; }

.playlist-Modal {
  position: fixed;
  z-index: 1000000;
  top: 45%;
  left: 50%;
  visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }
  .playlist-Modal.active {
    visibility: visible; }
  .playlist-Modal .modal-title {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #ccc; }
    .playlist-Modal .modal-title h4 {
      margin: 10px 0px;
      font-size: 14px; }
  .playlist-Modal .modal-body {
    padding-left: 5px; }
    .playlist-Modal .modal-body .title-input {
      margin-top: 10px;
      padding-left: 10px; }
      .playlist-Modal .modal-body .title-input span {
        display: block;
        font-family: 'Proxima Thin', Georgia, sans-serif;
        color: #aaaaaa;
        font-size: 15px;
        margin-bottom: 5px; }
      .playlist-Modal .modal-body .title-input input {
        width: 80%;
        font-family: 'Proxima Thin', Georgia, sans-serif;
        font-size: 15px;
        outline: none;
        padding: 0 88px 0 8px;
        border-radius: 4px;
        border: 0px;
        height: 28px; }
      .playlist-Modal .modal-body .title-input .counter {
        position: absolute;
        border-radius: 9px;
        right: 21px;
        margin-top: -24px;
        width: 50px;
        background-color: #506ab7;
        padding: 3px;
        text-align: center;
        font-size: 12px; }
    .playlist-Modal .modal-body .description {
      display: flex;
      margin: 20px 4px; }
      .playlist-Modal .modal-body .description .image {
        text-align: center;
        width: 34%;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px; }
        .playlist-Modal .modal-body .description .image span {
          text-align: left; }
        .playlist-Modal .modal-body .description .image img {
          width: 100%; }
      .playlist-Modal .modal-body .description .text {
        display: inline-block;
        width: 60%; }
        .playlist-Modal .modal-body .description .text textarea {
          resize: none;
          padding: 8px;
          width: 98%;
          height: 175px;
          border-radius: 5px;
          outline: none;
          font-family: 'Proxima Thin', Georgia, sans-serif; }
    .playlist-Modal .modal-body .error-message {
      height: 20px;
      background-color: #ca1f1f;
      border-radius: 5px;
      padding: 5px 15px;
      line-height: 20px;
      margin-right: 17px;
      margin-top: -13px;
      opacity: 0; }
      .playlist-Modal .modal-body .error-message.active {
        opacity: 1; }
      .playlist-Modal .modal-body .error-message span {
        padding-left: 20px;
        display: inline-block;
        color: white; }
    .playlist-Modal .modal-body .btn-section {
      text-align: center;
      padding-top: 10px; }
      .playlist-Modal .modal-body .btn-section .save-btn,
      .playlist-Modal .modal-body .btn-section .cancel-btn {
        font-size: 12px;
        border: 0px;
        border-radius: 20px;
        width: 125px;
        height: 30px;
        margin: 0px 5px;
        color: #fff; }
      .playlist-Modal .modal-body .btn-section .cancel-btn {
        background-color: #00000040;
        border: 1px solid #ccc; }
        .playlist-Modal .modal-body .btn-section .cancel-btn:hover {
          border-color: #fff; }
        .playlist-Modal .modal-body .btn-section .cancel-btn:active {
          border-color: #aaa; }
      .playlist-Modal .modal-body .btn-section .save-btn {
        background-color: #1db954; }
        .playlist-Modal .modal-body .btn-section .save-btn:hover {
          background-color: #1ed760; }
        .playlist-Modal .modal-body .btn-section .save-btn:active {
          background-color: #1aa34a;
          box-shadow: none; }
