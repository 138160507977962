@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
.seed-header {
  margin-top: 20px; }

.seed-entry {
  padding-top: 50px;
  width: 75%;
  height: 100%;
  margin: 0 auto; }

.seed-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px; }

.float-left {
  margin-right: 10px; }

.error {
  color: red;
  padding-top: 10px;
  font-size: 20px;
  text-align: center; }

form {
  max-width: 420px;
  margin: 50px auto 30px;
  padding: 20px; }

.feedback-input {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 0;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #1DB954;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0; }

.feedback-input:focus {
  border: 2px solid #2980b9; }

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical; }

.submit-btn {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background: #1DB954;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 100; }

.submit-btn:hover {
  background: #3498db;
  letter-spacing: 8px; }
