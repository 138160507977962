@keyframes slideshow {
  0% {
    left: 0; }
  100% {
    left: -200%; } }

.add-remove-section {
  display: flex; }
  .add-remove-section i {
    width: 20px;
    margin-top: 9px;
    margin-left: 10px;
    font-size: 23px;
    -webkit-text-stroke: 4px #282828 !important;
    color: #ccc; }
    .add-remove-section i:hover {
      color: white; }
    .add-remove-section i:active {
      color: #aaa; }
  .add-remove-section .fa-check {
    color: #01da01; }
    .add-remove-section .fa-check:hover:before {
      content: "\f00d"; }

.song-sider-container {
  display: flex;
  width: 40%;
  margin: 0 auto;
  align-items: center;
  margin-top: 0px; }
  .song-sider-container span {
    font-size: 10px;
    color: #aaa; }
    .song-sider-container span:first-of-type {
      padding-right: 4px; }
    .song-sider-container span:last-of-type {
      padding-left: 4px; }
  .song-sider-container .song-sider {
    margin: 0 auto;
    width: 90%;
    height: 4px;
    background-color: dimgray;
    transition: width 0.1s;
    border-radius: 4px; }
    .song-sider-container .song-sider .position-sider {
      background-color: #ccc; }
    .song-sider-container .song-sider .handler-sider {
      display: none; }
    .song-sider-container .song-sider:hover .position-sider {
      background-color: #21cc44 !important; }
    .song-sider-container .song-sider:hover .handler-sider {
      display: block;
      background: white;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      margin-top: -7px; }

.player-container .details-section,
.player-container .volume-control-container {
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  height: 56px;
  width: 200px;
  line-height: 14px;
  cursor: default;
  text-overflow: ellipsis; }

.player-container .details-section {
  left: 20px;
  top: 7px; }
  @media (max-width: 700px) {
    .player-container .details-section {
      width: 150px; } }
  @media (max-width: 550px) {
    .player-container .details-section {
      width: 100px; } }

.player-container .volume-control-container {
  right: 20px;
  top: 37px;
  display: flex; }
  @media (max-width: 750px) {
    .player-container .volume-control-container {
      display: none; } }
  .player-container .volume-control-container .fa {
    color: #ddd; }
    .player-container .volume-control-container .fa:hover {
      color: #fff; }
    .player-container .volume-control-container .fa:active {
      color: #aaa; }
  .player-container .volume-control-container .fa-desktop {
    margin-right: 30px;
    font-size: 13px; }
  .player-container .volume-control-container .volume-sider-container {
    max-width: 100px;
    width: 100%;
    margin-top: -2px; }
    .player-container .volume-control-container .volume-sider-container .fa {
      padding-right: 7px;
      width: 20px; }
    .player-container .volume-control-container .volume-sider-container .volumen {
      padding-right: 2px; }
    .player-container .volume-control-container .volume-sider-container .volume-sider {
      margin-bottom: 4px;
      padding-left: 10px;
      display: inline-block;
      width: 80%;
      height: 4px;
      background-color: dimgray;
      transition: width 0.1s;
      border-radius: 4px; }
      .player-container .volume-control-container .volume-sider-container .volume-sider .position-sider {
        background-color: #ccc; }
      .player-container .volume-control-container .volume-sider-container .volume-sider .handler-sider {
        display: inline; }
      .player-container .volume-control-container .volume-sider-container .volume-sider:hover .position-sider {
        background-color: #21cc44 !important; }
      .player-container .volume-control-container .volume-sider-container .volume-sider:hover .handler-sider {
        display: block;
        background: white;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-top: -7px; }

.player-container .song-name {
  font-family: "Proxima Nova", Georgia, sans-serif;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden; }
  .player-container .song-name:hover {
    text-decoration: underline;
    color: #fff; }
  .player-container .song-name.overflow {
    -webkit-transition: 5.3s;
    -moz-transition: 5.3s;
    transition: 5.3s;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    transition-timing-function: linear; }
    .player-container .song-name.overflow:hover {
      margin-left: -180px;
      text-overflow: initial;
      text-decoration: underline;
      color: #fff; }

.player-container .shuffle-song.active i,
.player-container .repeat-song.active i {
  color: #21cc44; }
  .player-container .shuffle-song.active i:hover,
  .player-container .repeat-song.active i:hover {
    color: #10ff41; }

@media (max-width: 700px) {
  .player-container .shuffle-song,
  .player-container .repeat-song {
    display: none; } }

.player-container .song-control .fa {
  padding: 0px 20px;
  -webkit-text-stroke: 1px #282828;
  color: #ccc; }
  .player-container .song-control .fa.play-btn {
    -webkit-text-stroke: 3px #282828; }
  .player-container .song-control .fa:hover {
    color: #fff; }
  .player-container .song-control .fa:active {
    color: #aaa !important; }

.song-control {
  justify-content: center;
  display: flex;
  margin-top: 20px; }

.control-btn {
  justify-content: center;
  display: flex; }

.control-btn.play-btn {
  position: relative;
  top: -10px;
  font-size: 35px;
  -webkit-text-stroke: 2px #333333; }
  .control-btn.play-btn:hover {
    font-size: 39px;
    margin: -2px -2px; }
  .control-btn.play-btn:active {
    font-size: 35px;
    margin: 0px 0px; }

.artist-name .artist:hover {
  text-decoration: underline;
  color: #fff; }
