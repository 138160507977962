.left-section {
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  position: fixed;
  top: 0px;
  bottom: 10px;
  width: 300px;
  background-color: #0e0e10;
  display: flex;
  flex-direction: column; }

.side-menu-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px;
  overflow-y: scroll; }

.buttom-section {
  text-align: center;
  width: 210px;
  position: fixed;
  bottom: 75px; }
  .buttom-section .new-playlist {
    height: 40px;
    padding-top: 20px;
    color: #b3b3b3;
    background-color: #0e0e10;
    border-top: 1px solid #2c2c2c;
    font-family: 'Proxima Thin', Georgia, sans-serif; }
    .buttom-section .new-playlist:hover {
      color: #fff;
      cursor: pointer; }
    .buttom-section .new-playlist .fa {
      padding-right: 5px; }

.queueBox {
  border: 1px solid white;
  width: 298px;
  text-align: center;
  line-height: 10vh;
  font-size: 30px; }
