@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
body {
  background: #181818;
  font-family: sans-serif;
  color: white;
  margin: 0 auto;
  padding: 0;
  cursor: default; }

.app {
  display: flex;
  position: relative; }
  .app .main-section {
    position: relative;
    margin: auto;
    margin-bottom: 100px; }
  .app .main-section-container {
    position: relative;
    top: 50px;
    padding-bottom: 50px; }
  @media (max-width: 1100px) {
    .app .main-section {
      padding-left: 200px; }
    .app .right-section {
      display: none; } }
  @media (max-width: 700px) {
    .app .main-section {
      padding-left: 0px; }
    .app .left-section {
      display: none; } }

.reset-button {
  position: absolute;
  top: 42px;
  right: 392px;
  color: white;
  height: 75px;
  width: 250px;
  background-color: #1DB954;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.reset-button:hover {
  background-color: #5ec482;
  letter-spacing: 0.8em; }

.spinner-container {
  width: 100%;
  height: 100%;
  display: table; }
  .spinner-container.section .sk-circle {
    top: -90px; }
    .spinner-container.section .sk-circle .sk-child:before {
      background-color: #b6b7b6; }
    @media (max-width: 1100px) and (min-width: 600px) {
      .spinner-container.section .sk-circle {
        left: 23%; } }

.sk-circle {
  width: 80px;
  height: 80px;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #1cd760;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.left-section {
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  position: fixed;
  top: 0px;
  bottom: 10px;
  width: 300px;
  background-color: #0e0e10;
  display: flex;
  flex-direction: column; }

.side-menu-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px;
  overflow-y: scroll; }

.buttom-section {
  text-align: center;
  width: 210px;
  position: fixed;
  bottom: 75px; }
  .buttom-section .new-playlist {
    height: 40px;
    padding-top: 20px;
    color: #b3b3b3;
    background-color: #0e0e10;
    border-top: 1px solid #2c2c2c;
    font-family: 'Proxima Thin', Georgia, sans-serif; }
    .buttom-section .new-playlist:hover {
      color: #fff;
      cursor: pointer; }
    .buttom-section .new-playlist .fa {
      padding-right: 5px; }

.queueBox {
  border: 1px solid white;
  width: 298px;
  text-align: center;
  line-height: 10vh;
  font-size: 30px; }

.details-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  background: #181818; }
  .details-container .user-image {
    border-radius: 50%;
    height: 30px; }
  .details-container .user-name {
    color: #fff;
    font-size: 14px;
    position: relative;
    top: -5px;
    margin-left: 10px;
    padding-right: 10px; }

.main-header {
  position: fixed;
  z-index: 1000;
  display: flex;
  height: 50px;
  margin: auto;
  right: 0;
  left: 0;
  justify-content: space-between;
  background: #181818;
  width: 1300px; }
  @media (max-width: 1760px) {
    .main-header {
      width: 1200px; } }
  @media (max-width: 1650px) {
    .main-header {
      width: 69%; } }
  @media (max-width: 1400px) {
    .main-header {
      width: 60%; } }
  @media (max-width: 1100px) {
    .main-header {
      width: 74%;
      left: 220px; } }
  @media (max-width: 850px) {
    .main-header {
      width: 65%;
      left: 220px; } }
  @media (max-width: 700px) {
    .main-header {
      width: 90%;
      left: 0; } }

.footer {
  position: fixed;
  margin-left: 298px;
  width: calc(100% - 298px);
  bottom: 0;
  left: 0;
  background: #282828;
  height: 300px;
  z-index: -500;
  display: flex;
  flex-direction: row; }

.footer > div {
  border: 1px solid white;
  text-align: center;
  width: 33.33333333%;
  font-size: 30px; }

.seed-header {
  margin-top: 20px; }

.seed-entry {
  padding-top: 50px;
  width: 75%;
  height: 100%;
  margin: 0 auto; }

.seed-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px; }

.float-left {
  margin-right: 10px; }

.error {
  color: red;
  padding-top: 10px;
  font-size: 20px;
  text-align: center; }

form {
  max-width: 420px;
  margin: 50px auto 30px;
  padding: 20px; }

.feedback-input {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 0;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #1DB954;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0; }

.feedback-input:focus {
  border: 2px solid #2980b9; }

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical; }

.submit-btn {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background: #1DB954;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 100; }

.submit-btn:hover {
  background: #3498db;
  letter-spacing: 8px; }

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 100000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.63);
  -webkit-transition: all 0.6s;
  transition: all 0.6s; }

.modal-content {
  background: #282828;
  width: 589px;
  border-radius: 6px;
  padding-top: 1px;
  padding-bottom: 10px;
  border-radius: 6px;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }
  @media (max-width: 600px) {
    .modal-content {
      width: 98%; }
      .modal-content input {
        width: 63% !important; }
      .modal-content textarea {
        width: 88% !important; }
      .modal-content button {
        display: block;
        width: 90% !important;
        margin-bottom: 20px !important; } }

.active .modal-content {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

.playlist-Modal.active,
.overlay.active {
  opacity: 1;
  visibility: visible; }

.playlist-Modal {
  position: fixed;
  z-index: 1000000;
  top: 45%;
  left: 50%;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }
  .playlist-Modal.active {
    visibility: visible; }
  .playlist-Modal .modal-title {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #ccc; }
    .playlist-Modal .modal-title h4 {
      margin: 10px 0px;
      font-size: 14px; }
  .playlist-Modal .modal-body {
    padding-left: 5px; }
    .playlist-Modal .modal-body .title-input {
      margin-top: 10px;
      padding-left: 10px; }
      .playlist-Modal .modal-body .title-input span {
        display: block;
        font-family: 'Proxima Thin', Georgia, sans-serif;
        color: #aaaaaa;
        font-size: 15px;
        margin-bottom: 5px; }
      .playlist-Modal .modal-body .title-input input {
        width: 80%;
        font-family: 'Proxima Thin', Georgia, sans-serif;
        font-size: 15px;
        outline: none;
        padding: 0 88px 0 8px;
        border-radius: 4px;
        border: 0px;
        height: 28px; }
      .playlist-Modal .modal-body .title-input .counter {
        position: absolute;
        border-radius: 9px;
        right: 21px;
        margin-top: -24px;
        width: 50px;
        background-color: #506ab7;
        padding: 3px;
        text-align: center;
        font-size: 12px; }
    .playlist-Modal .modal-body .description {
      display: flex;
      margin: 20px 4px; }
      .playlist-Modal .modal-body .description .image {
        text-align: center;
        width: 34%;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px; }
        .playlist-Modal .modal-body .description .image span {
          text-align: left; }
        .playlist-Modal .modal-body .description .image img {
          width: 100%; }
      .playlist-Modal .modal-body .description .text {
        display: inline-block;
        width: 60%; }
        .playlist-Modal .modal-body .description .text textarea {
          resize: none;
          padding: 8px;
          width: 98%;
          height: 175px;
          border-radius: 5px;
          outline: none;
          font-family: 'Proxima Thin', Georgia, sans-serif; }
    .playlist-Modal .modal-body .error-message {
      height: 20px;
      background-color: #ca1f1f;
      border-radius: 5px;
      padding: 5px 15px;
      line-height: 20px;
      margin-right: 17px;
      margin-top: -13px;
      opacity: 0; }
      .playlist-Modal .modal-body .error-message.active {
        opacity: 1; }
      .playlist-Modal .modal-body .error-message span {
        padding-left: 20px;
        display: inline-block;
        color: white; }
    .playlist-Modal .modal-body .btn-section {
      text-align: center;
      padding-top: 10px; }
      .playlist-Modal .modal-body .btn-section .save-btn,
      .playlist-Modal .modal-body .btn-section .cancel-btn {
        font-size: 12px;
        border: 0px;
        border-radius: 20px;
        width: 125px;
        height: 30px;
        margin: 0px 5px;
        color: #fff; }
      .playlist-Modal .modal-body .btn-section .cancel-btn {
        background-color: #00000040;
        border: 1px solid #ccc; }
        .playlist-Modal .modal-body .btn-section .cancel-btn:hover {
          border-color: #fff; }
        .playlist-Modal .modal-body .btn-section .cancel-btn:active {
          border-color: #aaa; }
      .playlist-Modal .modal-body .btn-section .save-btn {
        background-color: #1db954; }
        .playlist-Modal .modal-body .btn-section .save-btn:hover {
          background-color: #1ed760; }
        .playlist-Modal .modal-body .btn-section .save-btn:active {
          background-color: #1aa34a;
          box-shadow: none; }

@-webkit-keyframes slideshow {
  0% {
    left: 0; }
  100% {
    left: -200%; } }

@keyframes slideshow {
  0% {
    left: 0; }
  100% {
    left: -200%; } }

.add-remove-section {
  display: flex; }
  .add-remove-section i {
    width: 20px;
    margin-top: 9px;
    margin-left: 10px;
    font-size: 23px;
    -webkit-text-stroke: 4px #282828 !important;
    color: #ccc; }
    .add-remove-section i:hover {
      color: white; }
    .add-remove-section i:active {
      color: #aaa; }
  .add-remove-section .fa-check {
    color: #01da01; }
    .add-remove-section .fa-check:hover:before {
      content: "\F00D"; }

.song-sider-container {
  display: flex;
  width: 40%;
  margin: 0 auto;
  align-items: center;
  margin-top: 0px; }
  .song-sider-container span {
    font-size: 10px;
    color: #aaa; }
    .song-sider-container span:first-of-type {
      padding-right: 4px; }
    .song-sider-container span:last-of-type {
      padding-left: 4px; }
  .song-sider-container .song-sider {
    margin: 0 auto;
    width: 90%;
    height: 4px;
    background-color: dimgray;
    -webkit-transition: width 0.1s;
    transition: width 0.1s;
    border-radius: 4px; }
    .song-sider-container .song-sider .position-sider {
      background-color: #ccc; }
    .song-sider-container .song-sider .handler-sider {
      display: none; }
    .song-sider-container .song-sider:hover .position-sider {
      background-color: #21cc44 !important; }
    .song-sider-container .song-sider:hover .handler-sider {
      display: block;
      background: white;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      margin-top: -7px; }

.player-container .details-section,
.player-container .volume-control-container {
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  height: 56px;
  width: 200px;
  line-height: 14px;
  cursor: default;
  text-overflow: ellipsis; }

.player-container .details-section {
  left: 20px;
  top: 7px; }
  @media (max-width: 700px) {
    .player-container .details-section {
      width: 150px; } }
  @media (max-width: 550px) {
    .player-container .details-section {
      width: 100px; } }

.player-container .volume-control-container {
  right: 20px;
  top: 37px;
  display: flex; }
  @media (max-width: 750px) {
    .player-container .volume-control-container {
      display: none; } }
  .player-container .volume-control-container .fa {
    color: #ddd; }
    .player-container .volume-control-container .fa:hover {
      color: #fff; }
    .player-container .volume-control-container .fa:active {
      color: #aaa; }
  .player-container .volume-control-container .fa-desktop {
    margin-right: 30px;
    font-size: 13px; }
  .player-container .volume-control-container .volume-sider-container {
    max-width: 100px;
    width: 100%;
    margin-top: -2px; }
    .player-container .volume-control-container .volume-sider-container .fa {
      padding-right: 7px;
      width: 20px; }
    .player-container .volume-control-container .volume-sider-container .volumen {
      padding-right: 2px; }
    .player-container .volume-control-container .volume-sider-container .volume-sider {
      margin-bottom: 4px;
      padding-left: 10px;
      display: inline-block;
      width: 80%;
      height: 4px;
      background-color: dimgray;
      -webkit-transition: width 0.1s;
      transition: width 0.1s;
      border-radius: 4px; }
      .player-container .volume-control-container .volume-sider-container .volume-sider .position-sider {
        background-color: #ccc; }
      .player-container .volume-control-container .volume-sider-container .volume-sider .handler-sider {
        display: inline; }
      .player-container .volume-control-container .volume-sider-container .volume-sider:hover .position-sider {
        background-color: #21cc44 !important; }
      .player-container .volume-control-container .volume-sider-container .volume-sider:hover .handler-sider {
        display: block;
        background: white;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-top: -7px; }

.player-container .song-name {
  font-family: "Proxima Nova", Georgia, sans-serif;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden; }
  .player-container .song-name:hover {
    text-decoration: underline;
    color: #fff; }
  .player-container .song-name.overflow {
    -webkit-transition: 5.3s;
    transition: 5.3s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear; }
    .player-container .song-name.overflow:hover {
      margin-left: -180px;
      text-overflow: initial;
      text-decoration: underline;
      color: #fff; }

.player-container .shuffle-song.active i,
.player-container .repeat-song.active i {
  color: #21cc44; }
  .player-container .shuffle-song.active i:hover,
  .player-container .repeat-song.active i:hover {
    color: #10ff41; }

@media (max-width: 700px) {
  .player-container .shuffle-song,
  .player-container .repeat-song {
    display: none; } }

.player-container .song-control .fa {
  padding: 0px 20px;
  -webkit-text-stroke: 1px #282828;
  color: #ccc; }
  .player-container .song-control .fa.play-btn {
    -webkit-text-stroke: 3px #282828; }
  .player-container .song-control .fa:hover {
    color: #fff; }
  .player-container .song-control .fa:active {
    color: #aaa !important; }

.song-control {
  justify-content: center;
  display: flex;
  margin-top: 20px; }

.control-btn {
  justify-content: center;
  display: flex; }

.control-btn.play-btn {
  position: relative;
  top: -10px;
  font-size: 35px;
  -webkit-text-stroke: 2px #333333; }
  .control-btn.play-btn:hover {
    font-size: 39px;
    margin: -2px -2px; }
  .control-btn.play-btn:active {
    font-size: 35px;
    margin: 0px 0px; }

.artist-name .artist:hover {
  text-decoration: underline;
  color: #fff; }

.devices-container .overlay.active {
  opacity: 0; }

.devices {
  position: fixed;
  z-index: 1000000000;
  background: #282828;
  padding: 10px;
  width: 230px;
  bottom: 65px;
  right: 89px;
  min-height: 200px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0px 5px 5px -5px #000000; }
  .devices.hide {
    display: none; }
  .devices .devices-header {
    margin: 10px 0px; }
    .devices .devices-header h4 {
      display: inline;
      padding-right: 10px; }
    .devices .devices-header i {
      float: right;
      margin-right: 10px;
      margin-top: -2px; }
  .devices img {
    width: 100%; }
  .devices .no-results {
    font-size: 13px;
    color: #ccc;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera <7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */ }
    .devices .no-results div {
      margin: 15px 0px; }
  .devices .learn-more {
    height: 25px;
    width: 110px;
    border-radius: 25px;
    background-color: #0296e0;
    border: 0px;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px; }
    .devices .learn-more:hover {
      background-color: #23a6e8; }
    .devices .learn-more:active {
      background-color: #045d8a; }
  .devices .triangle {
    background: #282828;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 1;
    bottom: -9px;
    left: 0;
    right: 0;
    box-shadow: 4px 4px 7px -5px #000000;
    margin: auto;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .devices .device {
    display: flex;
    padding: 10px 0px; }
    .devices .device:last-of-type {
      margin-bottom: 20px; }
    .devices .device:hover {
      background-color: #3a3939; }
    .devices .device .icon {
      font-size: 40px;
      width: 40px;
      text-align: center;
      margin-right: 12px;
      padding-left: 10px; }
    .devices .device .details {
      text-align: left; }
      .devices .device .details h4 {
        font-size: 14px;
        margin: 5px 0px 2px; }
      .devices .device .details span {
        color: #aaa;
        font-size: 11px; }
    .devices .device.active {
      color: green; }
      .devices .device.active .fa {
        color: green; }
      .devices .device.active span {
        color: green; }

.now-playing {
  position: fixed;
  width: calc(100% - 300px);
  float: center;
  margin-left: 300px;
  bottom: 0;
  left: 0;
  padding-bottom: 400px;
  z-index: -500; }

.bck {
  position: fixed;
  width: calc(100% - 300px);
  margin-left: 300px;
  margin-bottom: 200px;
  height: calc(100% - 300px);
  z-index: -600;
  background: black; }

.arrow-line {
  width: 100%;
  height: 20%;
  display: inline-flex;
  justify-content: space-between; }

.album-art {
  height: 30%;
  width: 30%;
  margin: 0 auto;
  margin-bottom: 50px; }

.prompt {
  position: absolute;
  top: -300px;
  bottom: -50px;
  height: 30%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #1DB954;
  font-size: 2em;
  margin-bottom: 0px; }

.arrow {
  text-align: center;
  margin: 8% 0;
  width: 33%; }

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

.player-section {
  position: fixed;
  width: calc(100% - 300px);
  margin-left: 300px;
  bottom: 0;
  left: 0;
  height: 50px;
  padding-bottom: 350px;
  z-index: -500; }

.main-section-container {
  width: 1300px;
  height: 100%; }

@media (max-width: 1800px) {
  .main-section-container {
    width: 1100px; } }

@media (max-width: 1600px) {
  .main-section-container {
    width: 1000px; } }

@media (max-width: 1500px) {
  .main-section-container {
    width: 850px; } }

@media (max-width: 1400px) {
  .main-section-container {
    width: 750px; } }

@media (max-width: 1200px) {
  .main-section-container {
    width: 650px; } }

@media (max-width: 900px) {
  .main-section-container {
    width: 600px; } }

@media (max-width: 800px) {
  .main-section-container {
    width: 500px; } }

@media (max-width: 700px) {
  .main-section-container {
    width: 550px; } }

@media (max-width: 600px) {
  .main-section-container {
    width: 430px; } }

@media (max-width: 450px) {
  .main-section-container {
    width: 380px;
    margin: 0 auto; } }

