.now-playing {
  position: fixed;
  width: calc(100% - 300px);
  float: center;
  margin-left: 300px;
  bottom: 0;
  left: 0;
  padding-bottom: 400px;
  z-index: -500; }

.bck {
  position: fixed;
  width: calc(100% - 300px);
  margin-left: 300px;
  margin-bottom: 200px;
  height: calc(100% - 300px);
  z-index: -600;
  background: black; }

.arrow-line {
  width: 100%;
  height: 20%;
  display: inline-flex;
  justify-content: space-between; }

.album-art {
  height: 30%;
  width: 30%;
  margin: 0 auto;
  margin-bottom: 50px; }

.prompt {
  position: absolute;
  top: -300px;
  bottom: -50px;
  height: 30%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #1DB954;
  font-size: 2em;
  margin-bottom: 0px; }

.arrow {
  text-align: center;
  margin: 8% 0;
  width: 33%; }

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.player-section {
  position: fixed;
  width: calc(100% - 300px);
  margin-left: 300px;
  bottom: 0;
  left: 0;
  height: 50px;
  padding-bottom: 350px;
  z-index: -500; }

.main-section-container {
  width: 1300px;
  height: 100%; }

@media (max-width: 1800px) {
  .main-section-container {
    width: 1100px; } }

@media (max-width: 1600px) {
  .main-section-container {
    width: 1000px; } }

@media (max-width: 1500px) {
  .main-section-container {
    width: 850px; } }

@media (max-width: 1400px) {
  .main-section-container {
    width: 750px; } }

@media (max-width: 1200px) {
  .main-section-container {
    width: 650px; } }

@media (max-width: 900px) {
  .main-section-container {
    width: 600px; } }

@media (max-width: 800px) {
  .main-section-container {
    width: 500px; } }

@media (max-width: 700px) {
  .main-section-container {
    width: 550px; } }

@media (max-width: 600px) {
  .main-section-container {
    width: 430px; } }

@media (max-width: 450px) {
  .main-section-container {
    width: 380px;
    margin: 0 auto; } }
