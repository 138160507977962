.details-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  background: #181818; }
  .details-container .user-image {
    border-radius: 50%;
    height: 30px; }
  .details-container .user-name {
    color: #fff;
    font-size: 14px;
    position: relative;
    top: -5px;
    margin-left: 10px;
    padding-right: 10px; }
