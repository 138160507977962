body {
  background: #181818;
  font-family: sans-serif;
  color: white;
  margin: 0 auto;
  padding: 0;
  cursor: default; }

.app {
  display: flex;
  position: relative; }
  .app .main-section {
    position: relative;
    margin: auto;
    margin-bottom: 100px; }
  .app .main-section-container {
    position: relative;
    top: 50px;
    padding-bottom: 50px; }
  @media (max-width: 1100px) {
    .app .main-section {
      padding-left: 200px; }
    .app .right-section {
      display: none; } }
  @media (max-width: 700px) {
    .app .main-section {
      padding-left: 0px; }
    .app .left-section {
      display: none; } }

.reset-button {
  position: absolute;
  top: 42px;
  right: 392px;
  color: white;
  height: 75px;
  width: 250px;
  background-color: #1DB954;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.reset-button:hover {
  background-color: #5ec482;
  letter-spacing: 0.8em; }
