.footer {
  position: fixed;
  margin-left: 298px;
  width: calc(100% - 298px);
  bottom: 0;
  left: 0;
  background: #282828;
  height: 300px;
  z-index: -500;
  display: flex;
  flex-direction: row; }

.footer > div {
  border: 1px solid white;
  text-align: center;
  width: 33.33333333%;
  font-size: 30px; }
